import { useMyContext } from '../../context/Context'
import { HOME_ROUTE } from '../../variables/variables'
import BranchButton from '../branchButton/branchButton'
import c from './Apartments.module.scss'

const Apartments = () => {
    const { data } = useMyContext()
    return (
        <div className={c.apartments}>
            <p className={c.apartments__title}>
                {data === 'pl'
                    ? 'Strona "Mieszkania" w trakcie realizacji'
                    : "Apartment's page under construction"}
            </p>
            <BranchButton
                data={data}
                PLname="Głowna"
                ENname="Home"
                link={HOME_ROUTE}
            />
        </div>
    )
}

export default Apartments
